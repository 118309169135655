<template>
    <div class="process">
        <div class="process-top">
            <img :src="icon" alt="" />
            <div class="title">
                <span class="text1">{{ preTitle }}</span>
                <span class="text2">{{ title }}</span>
            </div>
        </div>

        <div class="content">
            <div class="stepInfo" v-for="(item, index) in steps" :key="index">
                {{ getName(item.text) }}
                <el-popover v-if="item.tips" :width="323" placement="bottom" trigger="hover">
                    <span class="iconfont" slot="reference">&#xe677;</span>
                    <div class="tips">
                        <div class="tip-info" v-for="(tips, tipsIndex) in item.tips" :key="tipsIndex">
                            <span>{{ getName(tips.text1) }}</span>
                            <span v-html="getTips(tips.text2)"></span>
                        </div>
                    </div>
                </el-popover>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Process',
    props: {
        icon: {
            type: String,
            default: ''
        },
        preTitle: {
            type: String,
            default: '阶段一 '
        },
        title: {
            type: String,
            default: '入驻申请'
        },
        steps: {
            type: Array,
            default: [
                {
                    text: '查询确认入驻资格'
                },
                {
                    text: '填写账号/企业信息进行注册'
                },
                {
                    text: '确认在线服务协议'
                }
            ]
        }
    },
    data() {
        return {
            position: ['']
        };
    },
    methods: {
        getTips(text) {
            if (text instanceof Array) {
                text = [...text].map(item => {
                    return this.getName(item);
                });
                return (
                    text.slice(0, text.length - 1) +
                    `<a  href="${window.location.origin + window.location.pathname}#question" >${
                        text[text.length - 1]
                    }</a>`
                );
            } else {
                return this.getName(text);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$primarys: [#2a90a0, #3b5996, #ca923a, #c66152];
.process {
    min-height: 100%;
    .process-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 24px 0 42px;

        img {
            width: 100px;
            height: 100px;
            display: block;
        }

        .title {
            display: flex;
            align-items: center;
            margin-top: 24px;
            .text1 {
                font-size: 16px;
                font-weight: bold;
                color: #ffffff;
                line-height: 22px;
                display: flex;
                align-items: center;

                &::after {
                    content: '';
                    width: 1px;
                    height: 18px;
                    background-color: #f2f3f5;
                    margin: 0 18px 0 14px;
                }
            }

            .text2 {
                font-size: 22px;
                font-weight: bold;
                color: #ffffff;
                line-height: 30px;
            }
        }
    }

    .content {
        padding: 26px 40px;

        .stepInfo {
            // max-width: 270px;
            position: relative;
            font-size: 14px;
            color: #292c33;
            line-height: 20px;
            .iconfont {
                font-size: 14px;
                color: #ffa526;
                line-height: 20px;
                cursor: pointer;
            }
            &:not(:last-child) {
                padding-bottom: 30px;
                &::after {
                    content: '';
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    left: -13px;
                    top: 10px;
                }
            }

            &::before {
                content: '';
                width: 7px;
                height: 7px;
                position: absolute;
                left: -16px;
                top: 7px;
                border-radius: 7px;
            }
        }
    }
}

@for $i from 1 through 4 {
    .process-#{$i} {
        background-color: rgba(nth($primarys, $i), 10%);
        .process-top {
            background-color: nth($primarys, $i);
        }
        .content {
            .stepInfo {
                &::before {
                    background-color: nth($primarys, $i);
                }
                &:not(:last-child) {
                    &::after {
                        background-color: nth($primarys, $i);
                    }
                }
            }
        }
    }
}

.tips {
    .tip-info {
        display: flex;

        font-size: 12px;
        color: #878d99;
        line-height: 17px;
        span {
            &:first-child {
                min-width: 60px;
            }
        }
    }
    .tip-info + .tip-info {
        margin-top: 10px;
    }
}

.isEn {
    .process .process-top .title {
        .text1 {
            font-size: 0.18rem;
        }
        .text2 {
            font-size: 0.2rem;
        }
    }
}
</style>

<style lang="scss">
.el-popover {
    .tip-info {
        span {
            a {
                color: #ffa526;
                &:hover {
                    text-decoration: underline;
                }

                font-size: 12px;
                line-height: 17px;
            }
        }
    }
}
</style>
