<template>
    <div class="manufacturersEntering">
        <div class="banner">
            <auto-img :src="require('../../assets/ruzhu_top_bg2x.jpg')" mul="19%"></auto-img>
            <div class="banner-text">
                <div class="text1 fontYahei">{{ $t('imt-ke-ji-ping-tai-hui-yuan-ru-zhu-zhi-nan') }}</div>
                <div class="text2 fontYahei">
                    {{
                        $t(
                            'imt-xie-shou-you-xiu-qi-ye-gong-tong-shi-xian-zhi-neng-zhi-zao-chan-ye-lian-xin-xi-ji-cheng-yu-gong-xiang'
                        )
                    }}
                </div>
                <el-button type="warning" @click="goEnter">{{ $t('li-ji-ru-zhu') }}</el-button>
            </div>
        </div>

        <div class="steps">
            <title-driver class="hot-title">
                <span slot="title">{{ $t('ru-zhu-liu-cheng') }}</span>
                <span slot="sub">PROCESS TO JOIN</span>
            </title-driver>
            <el-row :gutter="20" type="flex">
                <el-col :span="6" v-for="(item, index) in processes" :key="index">
                    <Process
                        :icon="item.icon"
                        :preTitle="getName(item.preTitle)"
                        :title="getName(item.title)"
                        :steps="item.steps"
                        :class="'process-' + (index + 1)"
                        @goQuestion="goQuestion"
                    />
                </el-col>
            </el-row>
        </div>

        <div class="fee">
            <title-driver>
                <span slot="title">{{ $t('gong-neng-quan-yi') }}</span>
                <span slot="sub">FUNCTIONS / RIGHT AND INTEREST</span>
            </title-driver>

            <div class="fee-tips">
                {{
                    $t(
                        'yong-hu-shen-qing-ru-zhu-hui-yuan-mo-ren-kai-tong-pu-tong-hui-yuan-quan-yi-ke-yi-shang-chuan-chan-pin-bian-ji-fen-lei-wei-hu-hui-yuan-dian-pu-ye-de-xiang-guan-xin-xi-ru-xu-yao-dian-pu-ye-ge-xing-hua-ding-zhi-ji-guang-gao-pai-fang-jing-jia-pai-ming-deng-zeng-zhi-fu-wu-ke-yi-lian-xi-ke-fu-le-jie-xiang-qing'
                    )
                }}
            </div>

            <FeeTable />
        </div>

        <div class="question">
            <div class="questionMain" id="question">
                <title-driver>
                    <span slot="title">{{ $t('chang-jian-wen-ti') }}</span>
                    <span slot="sub">FAQ</span>
                </title-driver>

                <div class="question-info" v-for="(item, index) in questions" :key="index">
                    <div class="text1">{{ index + 1 }}. {{ $t(item.q) }}</div>
                    <div class="text2">
                        <div v-for="(a, aIndex) in item.a" :key="aIndex">{{ $t(a) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import Process from '../../components/manufacturers/Process.vue';
import FeeTable from '../../components/vip/FeeTable.vue';
export default {
    name: 'ManufacturersEntering',
    data() {
        return {
            search: '',
            processes: [
                {
                    icon: require('../../assets/ManufacturersEntering/ruzhu_icon_jieduan01.png'),
                    preTitle: {
                        chName: '阶段一',
                        enName: 'NO.1'
                    },
                    title: {
                        chName: '入驻申请',
                        enName: 'Membership Application'
                    },
                    steps: [
                        {
                            text: {
                                chName: '查询确认入驻资格',
                                enName: 'Check Areas of Member'
                            },
                            tips: [
                                {
                                    text1: {
                                        chName: '查看企业：',
                                        enName: 'Check:'
                                    },
                                    text2: {
                                        chName: '平台是否已经存在本企业，进行企业认领',
                                        enName:
                                            'Already a T-PARK member? Contact your colleague to maintain Online Showroom together!'
                                    }
                                },
                                {
                                    text1: {
                                        chName: '查看类别：',
                                        enName: 'Areas: '
                                    },
                                    text2: [
                                        {
                                            chName: '企业生产或经营的产品包含于智羿园区 T-PARK 产品类别范围内，',
                                            enName:
                                                "Products/Services shall be included in the scope of T-PARK member's area."
                                        },
                                        {
                                            chName: '点击查看招商总类别',
                                            enName: 'Click for detail.'
                                        }
                                    ]
                                }
                            ]
                        },

                        {
                            text: {
                                chName: '确认在线服务协议',
                                enName: 'Confirm and agree with the Membership Agreement'
                            }
                        },
                        {
                            text: {
                                chName: '填写账号/企业信息进行注册',
                                enName: 'Create company profile for registration'
                            }
                        }
                    ]
                },
                {
                    icon: require('../../assets/ManufacturersEntering/ruzhu_icon_jieduan02.png'),
                    preTitle: { chName: '阶段二', enName: 'NO.2' },
                    title: { chName: '信息审核', enName: 'Qualification Review' },
                    steps: [
                        {
                            text: {
                                chName: '平台人工审核企业资质，并于7个工作日内反馈',
                                enName: 'Qualification reviewed by T-PARK. Up to 7 working days for result.'
                            }
                        },
                        {
                            text: {
                                chName: '商家可随时在个人中心查询入驻审核进度',
                                enName: 'Review progress could be checked through Management Center'
                            }
                        }
                    ]
                },
                {
                    icon: require('../../assets/ManufacturersEntering/ruzhu_icon_jieduan03.png'),
                    preTitle: { chName: '阶段三', enName: 'NO.3' },
                    title: { chName: '功能开通', enName: 'Activate Online Showroom' },
                    steps: [
                        {
                            text: {
                                chName: '审核通过后，开通会员功能',
                                enName: 'Function and feature will be unlocked after passing the review.'
                            }
                        }
                    ]
                },
                {
                    icon: require('../../assets/ManufacturersEntering/ruzhu_icon_jieduan04.png'),
                    preTitle: { chName: '阶段四', enName: 'No.4' },
                    title: { chName: '店铺上线', enName: 'Online Showroom Launching' },
                    steps: [
                        {
                            text: { chName: '装修店铺', enName: 'Online Showroom Management' }
                        },
                        {
                            text: { chName: '管理产品类别', enName: 'Products Series Management' }
                        },
                        {
                            text: { chName: '上传产品', enName: 'Product Management' }
                        }
                    ]
                }
            ],
            feeData: [
                {
                    type: {
                        chName: '功能/权益',
                        enName: 'Function/Right and Interest'
                    },
                    colspan: 2,
                    isTop: true
                },
                {
                    type: {
                        chName: '展厅装修',
                        enName: 'Online Showroom Management (HomePage)'
                    },
                    name: { chName: '展厅主页装修', enName: 'Decoration for company HomePage' },
                    exhibitors: true,
                    vip: true,
                    vvip: true,
                    rowspan: 6
                },
                {
                    name: { chName: '产品上传/维护', enName: 'Product upload and maintenance' },
                    exhibitors: true,
                    vip: true,
                    vvip: true
                },
                {
                    name: { chName: '自定义展位排版', enName: 'Customized layout for company HomePage' },
                    exhibitors: false,
                    vip: true,
                    vvip: true
                },
                {
                    name: { chName: '自定义导航条颜色', enName: 'Customized navigational bar for Online Showroom' },
                    exhibitors: false,
                    vip: true,
                    vvip: true
                },
                {
                    name: { chName: '在轮播图中添加视频', enName: 'Video upload for Banner' },
                    exhibitors: false,
                    vip: true,
                    vvip: true
                },
                {
                    name: { chName: '顶部轮播图数量', enName: 'Number of Banner' },
                    exhibitors: '2张',
                    vip: '不限',
                    vvip: '不限'
                },
                {
                    type: { chName: '后台功能', enName: 'Management Center' },
                    name: { chName: '信息更新维护', enName: 'Online Showroom content maintenance' },
                    exhibitors: true,
                    vip: true,
                    vvip: true,
                    rowspan: 5
                },
                {
                    name: { chName: '产品维护', enName: 'Product maintenance' },
                    exhibitors: true,
                    vip: true,
                    vvip: true
                },
                {
                    name: { chName: '平台代理维护', enName: 'Content maintenance service by IMT ' },
                    exhibitors: false,
                    vip: false,
                    vvip:
                        'IMT为会员进行网站内容的上传、编辑与维护，会员按照网站内容结构负责提供所涉及内容的文档、资料、图片等。'
                },
                {
                    name: { chName: '网站内容翻译' },
                    exhibitors: false,
                    vip: false,
                    vvip: 'IMT为会员提供网站信息内容的翻译服务，保证网站中文版本与英文版本内容的对应与完整'
                },
                {
                    name: { chName: '客服问询信息查询/回复', enName: 'Customers service' },
                    exhibitors: true,
                    vip: true,
                    vvip:
                        'IMT协助会员进行网站客户服务，对到访用户的问询信息进行查询、反馈与维护管理等，保障对到访用户的问询信息进行及时的反馈与处理'
                },
                {
                    type: { chName: '推广展示', enName: 'Promotion Display' },
                    subType: '每个推广位投放不少于7天最多 15天',
                    name: { chName: '平台主页广告推广', enName: 'Home page promotion' },
                    exhibitors: '200元/条',
                    vip: '20条/年',
                    vvip: '20条/年',
                    rowspan: 5
                },
                {
                    name: { chName: '新闻活动简报广告', enName: 'Promotion area of News' },
                    exhibitors: '150元/条',
                    vip: '15条/年',
                    vvip: '15条/年'
                },
                {
                    name: { chName: 'BANNER位推广', enName: 'BANNER' },
                    exhibitors: '200元/条',
                    vip: '20条/年',
                    vvip: '20条/年'
                },
                {
                    name: { chName: '相关产品位', enName: 'Related products' },
                    exhibitors: '150元/条',
                    vip: '15条/年',
                    vvip: '15条/年'
                },
                {
                    name: { chName: '产品展示竞价排名', enName: 'Ranking optimization' },
                    exhibitors: false,
                    vip: '企业及产品排序优化',
                    vvip: '企业及产品排序优化'
                },
                {
                    type: { chName: '数据服务', enName: 'Information and Data' },
                    name: '采购商信息查询',
                    exhibitors: false,
                    vip: '免费查询',
                    vvip: '免费查询',
                    rowspan: 3
                },
                {
                    name: { chName: '店铺访问/用户行为数据', enName: 'Visiting Data' },
                    exhibitors: false,
                    vip: '免费查询',
                    vvip: '免费查询'
                },
                {
                    name: { chName: '相关数据统计报告', enName: 'Report' },
                    exhibitors: false,
                    vip: true,
                    vvip: true
                }
            ],
            questions: [
                {
                    q: 'question1',
                    a: ['question1-1']
                },
                {
                    q: 'question2',
                    a: ['question2-1', 'question2-2']
                },
                {
                    q: 'question3',
                    a: ['question3-1', 'question3-2', 'question3-3', 'question3-4']
                },
                {
                    q: 'question4',
                    a: ['question4-1']
                },
                {
                    q: 'question5',
                    a: ['question5-1']
                }
            ]
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    components: {
        Process,
        FeeTable
    },
    methods: {
        goEnter() {
            this.checkLogin()
                .then(() => {
                    this.$router.push('/enterRule1');
                })
                .catch(e => {
                    if (e === '未登录') {
                        this.login();
                    }
                });
        },
        goQuestion() {
            document.documentElement.scrollTo({
                top: document.getElementById('question').offsetTop,
                behavior: 'smooth'
            });
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.manufacturersEntering {
    background-color: #fff;
}
.banner {
    position: relative;

    .banner-text {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        // background-color: rgba($color: #000000, $alpha: 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .text1 {
            font-size: 33px;
            font-weight: bold;
            color: #ffffff;
            line-height: 44px;
            margin-bottom: 30px;
        }

        .text2 {
            font-size: 22px;
            color: #ffffff;
            line-height: 29px;
            letter-spacing: 1px;
            margin-bottom: 50px;
        }

        .el-button {
            min-width: 272px;
            box-sizing: border-box;
        }
    }
}

.steps {
    @include center-content(260);
    padding-top: 111px;
    padding-bottom: 83px;

    .el-row {
        margin-top: 74px;
    }
}
.fee {
    @include center-content(360);
    padding-bottom: 140px;
    .fee-tips {
        font-size: 12px;
        color: #292c33;
        line-height: 24px;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 50px;

        @include center-content(100, 1200);
    }
}

@media screen and (max-width: 1920px) {
    .autoImg {
        padding-top: 360px !important;
    }
}

.question {
    padding: 80px 0;
    background-color: #f5f7fa;

    .questionMain {
        @include center-content(421);
    }
    .question-info {
        margin-top: 50px;
        .text1 {
            font-size: 16px;
            font-weight: bold;
            color: #292c33;
            line-height: 22px;
        }

        .text2 {
            font-size: 14px;
            color: #292c33;
            line-height: 24px;
            margin-top: 10px;
        }
    }
}

.isEn {
    .banner .banner-text {
        padding: 0 50px;
        .tetx1 {
            font-size: 26px;
            margin-bottom: 0.2rem;
        }

        .text2 {
            font-size: 18px;
            text-align: center;
            margin-bottom: 0.3rem;
        }
    }
}
</style>
